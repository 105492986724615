import React, { useEffect } from 'react';
import {
  Box, Checkbox,
  Text,
  VStack, Input,
  Button, CircularProgress, Image, SimpleGrid,
  Center, 
  Card, CardHeader, CardBody, Heading
} from '@chakra-ui/react';
import { useAppStateStore } from "./AppStateProvider";
import { PlusSquareIcon } from '@chakra-ui/icons'


function Collection() {
  const { appState, setAppState } = useAppStateStore();
  
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });  
  
  const addToCart = (book_id) => {
    setAppState({ ...appState, loading: book_id, });
    
    fetch(appState.endpoint + 'cart', {
      method : "POST",
      body   : JSON.stringify({
        id    : book_id,
        token : appState.token,
      })
    })
    .then((res) => {
      res.json().then((data) => {
        if ('OK' === data.status) {
          let thisBook = {};
          const books  = appState.books.reduce((prev, curr) => {
            if (curr.id === book_id) {
              thisBook = curr;
            }
            else {
              prev.push(curr);
            }
            
            return prev;
          }, []);   
          
          const display = appState.display.reduce((prev, curr) => {
            if (curr.id === book_id) {
              // pass
            }
            else {
              prev.push(curr);
            }
            
            return prev;
          
          }, []);
          
          const cartBooks = [ ...appState.cart.books, thisBook, ];
          const cart = { ...appState.cart, books: cartBooks, chosenRate: {cost:0}, rates: [], };
          setAppState({ ...appState, books: books, cart: cart, loading: false, display: display, });                
        }
        else {
          setAppState({ ...appState, loading: false, });
          alert('error');
        }
      })
    })
    .catch((err) => {
      console.error(err);
      setAppState({ ...appState, loading: false, });
      alert('error');
    });        
  };

  if (appState.books.length) {
    const collection = appState.display.map((b) => {
      return (
        <Card width='90%' variant='elevated' key={b.id}>
          <CardHeader>
            <Heading size='md'>
              <Button colorScheme='orange' variant='link' size="lg" onClick={ () => {addToCart(b.id);} } isLoading={(appState.loading && appState.loading === b.id)}>
                <PlusSquareIcon /> &nbsp; add to cart &nbsp; 
              </Button><br />                
              {b.title}  
            </Heading>
          </CardHeader>
          <CardBody>
            
            <VStack spacing='2' align='left'>
              {
                b.image && 
                <Image src={b.image} alt="Book Cover" />
              }
              {
                b.authors &&
                <Text align='left' pt='0' fontSize='sm'>by {b.authors.join(', ')}</Text>
              }
              {
                b.edition && 
                <Text align='left' pt='0' fontSize='sm'>Edition: {b.edition}</Text>
              }
              <Text align='left' pt='0' fontSize='sm'>{b.binding}</Text>
              <Text align='left' pt='0' fontSize='sm'>Publisher: {b.publisher}</Text>
              <Text align='left' pt='0' fontSize='sm'>Published: {b.date_published}</Text>
              <Text align='left' pt='0' fontSize='sm'>Condition: {b.condition}</Text>
              {
                b.description && 
                <Text align='left' pt='0' fontSize='sm'>{b.description}</Text>
              }            
              <Text pt='0' fontSize='sm'>{USDollar.format(b.price.replace(/[^\d.]/, ''))}</Text>
            </VStack>
            
          </CardBody>
        </Card>      
      );
    });
    return collection;  
  }
  else {
    return (
      <Center><CircularProgress value={33} size='120px' /></Center>
    );
  }
}


function Books() {
  const { appState, setAppState } = useAppStateStore();

  useEffect(() => {
    fetch(appState.endpoint + 'books', {
      method : "GET"
    })
    .then((res) => {
      res.json().then((data) => {
        
        data.collection.sort((a,b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          else if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          else {
            return 0;
          }
        });
        
        // hide duplicate titles
        const display = data.collection.reduce((list, curr) => {
          if (list.length) {
            const prev = list[(list.length - 1)];
            
            if ((prev.isbn && prev.isbn === curr.isbn) || (prev.title && prev.authors && prev.title === curr.title && prev.authors === curr.authors)) {
              // skip
            }
            else {
              list.push(curr);
            }            
          }
          else {
            list.push(curr);
          }
          
          return list;
        }, []);
      
        const cart = { ...appState.cart, books: data.cart, };
        setAppState({ 
          ...appState, 
          books   : data.collection, 
          display : display,
          dispAll : display,
          search  : '',
          token   : data.token, 
          cart    : cart, 
        });
      })
    })
    .catch((err) => {
      console.error(err);
      alert('error');
    });    
  }, []);

  const runSearch = (e) => {
    const term = e.target.value.toLowerCase();
    if (term) {
      const display = appState.books.reduce((prev, curr) => {
        if (curr.title.toLowerCase().match(term)) {
          prev.push(curr);
        }
        else {
          const matches = curr.authors.reduce((p, c) => {
            if (c.toLowerCase().match(term)) {
              return true;
            }
            else {
              return p;
            }
          }, false);
          if (matches) {
            prev.push(curr);
          }
        }
        return prev;
      }, []);
      setAppState({ ...appState, display: display, search: e.target.value, });
    }
    else {
      setAppState({ ...appState, display: appState.dispAll, search : '', });
    }
  };
    
  const toggleMFC = () => {
    if (appState.MFC) {
      setAppState({ ...appState, MFC: false, display : appState.dispAll, });
    }
    else {
      const display = appState.dispAll.reduce((prev, curr) => {
        const authstring = curr.authors.join(' ');
      
        if (
        (curr.title.match(/^Soul Work/i) && authstring.match(/^Marjorie Bowens/i))
          ||
        (curr.title.match(/^Worship That Works/i) && authstring.match(/^Wayne Arnason/i))
          ||
        (curr.title.match(/^Song and Spirit/i) && authstring.match(/^Erika Hewitt/i))
          ||
        (curr.title.match(/^Thematic Preaching/i) && authstring.match(/^Ken Sawyer/i))
          ||
        (curr.title.match(/^Soul Repair$/i) && authstring.match(/^Rita Nakashima Brock/i))
          ||
        (curr.title.match(/^Making Love Just/i) && authstring.match(/^Marvin Ellison/i))
          ||
        (curr.title.match(/^A Time to Build/i) && authstring.match(/^Debra Haffner/i))
          ||
        (curr.title.match(/^Privilege/i) && curr.title.match(/Power/i) && curr.title.match(/Difference$/i) && authstring.match(/^Allan Johnson/i))
          ||
        (curr.title.match(/^Injustice and the Care of Souls/i) && authstring.match(/^Sheryl Kujawa/i))
          ||
        (curr.title.match(/^Spiritual Guidance Across Religions/i) && authstring.match(/^John/i) && authstring.match(/Mabry$/i))
          ||
        (curr.title.match(/^How Your Church Family Works/i) && authstring.match(/^Peter Steinke/i))
          ||
        (curr.title.match(/^Women Out of Order/i) && curr.title.match(/Risking Change and Creating Care in a Multicultural World$/i))
          ||
        (curr.title.match(/^Understanding the Bible/i) && authstring.match(/^John Buehrens/i))
          ||
        (curr.title.match(/^Nurturing Children and Youth/i) && authstring.match(/^Tracey Hurd/i))
          ||
        (curr.title.match(/^Coming of Age Handbook for Congregations/i) && authstring.match(/Millspaugh$/i))
          ||
        (curr.title.match(/^Claiming the Past/i) && curr.title.match(/Shaping the Future/i) && curr.title.match(/Four Eras in Liberal Religious Education$/i))   
          ||
        (curr.title.match(/^Sacred Service in Civic Space/i) && authstring.match(/^Kathleen Parker/i))  
          ||
        (curr.title.match(/^The Selma Awakening/i) && authstring.match(/^Mark Morrison/i))   
          ||
        (curr.title.match(/^Justice on Earth/i) && authstring.match(/^Jennifer Nordstrom/i))  
          ||
        (curr.title.match(/^Reclaiming Prophetic Witness/i) && authstring.match(/^Paul Rasor/i))
          ||
        (curr.title.match(/^Bridging the Class Divide and Other Lessons for Grassroots Organizing/i) && authstring.match(/^Linda Stout/i))
          ||
        (curr.title.match(/^In the Interim/i) && curr.title.match(/Strategies for Interim Ministers and Congregations$/i))
          ||
        (curr.title.match(/^Governance and Ministry/i) && authstring.match(/^Dan Hotchkiss/i))
          ||
        (curr.title.match(/In Charge Here/i) && curr.title.match(/The Complex Relationship Between Ministry and Authority$/i))
          ||
        (curr.title.match(/^A Documentary History of Unitarian Universalism/i) && authstring.match(/^Dan McKanan/i))
          ||
        (curr.title.match(/^Class Action/i) && authstring.match(/Commission on Appraisal$/i))
          ||
        (curr.title.match(/^Centering/i) && curr.title.match(/Navigating Race/i) && authstring.match(/^Mitra Rahnema/i))
          ||
        (curr.title.match(/^A House for Hope/i) && authstring.match(/^John Buehrens/i))
          ||
        (curr.title.match(/^Faith Without Certainty/i) && authstring.match(/^Paul Rasor/i))
          ||
        (curr.title.match(/^The Growing Church/i) && authstring.match(/^Thom Belote/i))
          ||
        (curr.title.match(/^Leadership on the Line/i) && authstring.match(/^Martin Linsky/i))
          ||
        (curr.title.match(/^The Practice of Adaptive Leadership/i) && authstring.match(/^Ronald Heifetz/i))
          ||
        (curr.title.match(/^Beyond Fundraising/i) && authstring.match(/^Wayne Clark/i))
          ||
        (curr.title.match(/^The Generosity Path/i) && authstring.match(/^Mark Ewert/i))
          ||
        (curr.title.match(/^The Abundance of Our Faith/i) && authstring.match(/^Terry Sweetser/i))
          ||
        (curr.title.match(/^Universalists and Unitarians in America/i) && authstring.match(/^John Buehrens/i))
          ||
        (curr.title.match(/^An Introduction to the Unitarian and Universalist Traditions/i) && authstring.match(/^Mark Harris/i))
          ||
        (curr.title.match(/^Unitarian Universalism/i) && authstring.match(/^David Bumbaugh/i))
          ||
        (curr.title.match(/^The Unitarians and the Universalists/i) && authstring.match(/^David Robinson/i))
          ||
        (curr.title.match(/^The Cathedral of the World|Love and Death/i) && authstring.match(/^Forrest Church/i))
          ||
        (curr.title.match(/^Is God a White Racist/i) && authstring.match(/^William Jones/i))
          ||
        (curr.title.match(/^Reason and Reverence/i) && authstring.match(/Murry$/i))
          ||
        (curr.title.match(/^(?:Saving Paradise|Proverbs of Ashes)/i) && authstring.match(/^Rebecca Ann Parker/i))
          ||
        (authstring.match(/^Anthony Pinn/i) && curr.title.match(/^(?:Essays on Race, Religion, and Popular Culture|What Is Humanism and Why Does It Matter|When Colorblindness|The End of God|Terror and Triumph)/i))
          ||
        (curr.title.match(/^Religious Naturalism Today/i) && authstring.match(/^Jerome Stone/i))
          ||
        (authstring.match(/^Sharon Welch/i) && curr.title.match(/^(?:After Empire|Sweet Dreams in America|A Feminist Ethic of Risk|Communities of Resistance and Solidarity)/i))
          ||
        (curr.title.match(/^The Essential James Luther Adams/i) && authstring.match(/^James Luther Adams/i))
          ||
        (curr.title.match(/^An Examined Faith/i) && curr.title.match(/Social Context and Religious Commitment/i))
          ||
        (curr.title.match(/^On Being Human Religiously/i) && authstring.match(/Stackhouse/i))
          ||
        (curr.title.match(/^Omnipotence and Other Theological Mistakes/i) && authstring.match(/^Charles Hartshorne/i))
          ||
        (curr.title.match(/^A Natural Theology for Our Times/i))
          ||
        (curr.title.match(/^A Religion for Greatness/i) && authstring.match(/^Clarence Skinner/i))
          ||
        (curr.title.match(/^We Would Be One/i) && authstring.match(/^Wayne Arnason/i))
          ||
        (curr.title.match(/^Joseph Priestley and English Unitarianism in America/i) && authstring.match(/Bowers/i))
          ||
        (curr.title.match(/^The Universalist Movement in America/i) && authstring.match(/^Ann Lee Bressler/i))
          ||
        (curr.title.match(/^Long Challenge/i) && authstring.match(/^Victor Carpenter/i))
          ||
        (curr.title.match(/^Standing Before Us/i) && curr.title.match(/Women and Social Reform/i))
          ||
        (curr.title.match(/^Missionaries, Builders, and Pathfinders/i) && authstring.match(/^Gail Forsyth/i))
          ||
        (curr.title.match(/^Southern Witness/i) && authstring.match(/^Gordon Gibson/i))
          ||
        (curr.title.match(/^American Transcendentalism/i) && authstring.match(/^Philip Gura/i))
          ||
        (curr.title.match(/^Racovia/i) && authstring.match(/^Philip Hewett/i))
          ||
        (curr.title.match(/^For Faith and Freedom/i) && authstring.match(/Howe$/))
          ||
        (authstring.match(/^Megan Marshall/i) && curr.title.match(/^(?:The Peabody Sisters|Margaret Fuller)/i))  
          ||
        (authstring.match(/^John Matteson/i) && (curr.title.match(/The Story of Louisa May Alcott and Her Father$/i) || curr.title.match(/^The Lives of Margaret Fuller/i)))
          ||
        (curr.title.match(/^Identifying the Image of God/i) && authstring.match(/^Dan McKanan/i))
          ||
        (authstring.match(/^Mark Morrison/i) && curr.title.match(/^(?:Black Pioneers in a White Denomination|Darkening the Doorways)/i))
          ||
        (curr.title.match(/^Children of the Same God/i) && authstring.match(/^Susan Ritchie/i))
          ||
        (curr.title.match(/^The Premise and the Promise/i) && authstring.match(/^Warren/i) && authstring.match(/Ross$/i))
          ||
        (curr.title.match(/^Restless Souls/i) && authstring.match(/^Leigh Eric Schmidt/i))
          ||
        (curr.title.match(/^Making the Manifesto/i) && authstring.match(/Schultz$/i))
          ||
        (authstring.match(/^Cynthia Grant Tucker/i) && curr.title.match(/^(?:The Prophetic Sisterhood|No Silent Witness)/i))
          ||
        (curr.title.match(/^The Fellowship Movement/i) && authstring.match(/^Holley Ulbrich/i))
          ||
        (curr.title.match(/^American Universalism/i) && authstring.match(/^George Huntston Williams/i))
          ||
        (authstring.match(/^Conrad Wright/i) && curr.title.match(/^(?:The Beginnings of Unitarianism in America|The Liberal Christians)/i))
        ) {
          curr.description = '(MFC Required Readings) ' + curr.description;
          prev.push(curr);
        }
        else if (
          (curr.title.match(/^Lifting Our Voices/i) && curr.title.match(/Readings in the Living Tradition$/i))
            ||
          (curr.title.match(/^Las Voces del Camino/i))
            ||
          (curr.title.match(/^Singing the Journey/i))
            ||
          (curr.title.match(/^Encounters/i) && authstring.match(/^Paula Cole Jones/i))
            ||
          (curr.title.match(/^Been in the Storm So Long/i) && authstring.match(/^Mark Morrison/i))
            ||
          (curr.title.match(/^Voices from the Margins/i) && authstring.match(/^Mark Morrison/i))
            ||
          (curr.title.match(/^Becoming/i) && authstring.match(/^Kayla Parker/i))
            ||
          (curr.title.match(/^A Long Time Blooming/i) && authstring.match(/^Marta Valentin/i))
            ||
          (curr.title.match(/^UUA Commission on Appraisal Reports/i))
            ||
          (curr.title.match(/^Elite/i) && authstring.match(/^Mark Harris/i))
            ||
          (curr.title.match(/^Unitarian Universalist Pocket Guide/i))
            ||
          (curr.title.match(/^The Arc of the Universe is Long/i) && authstring.match(/^Leslie Takahashi/i))
            ||
          (curr.title.match(/^The New Jim Crow/i) && authstring.match(/^Michelle Alexander/i))
            ||
          (curr.title.match(/^Trails of Hope and Terror/i) && authstring.match(/^Miguel de la Torre/i))
            ||
          (curr.title.match(/^The Reproductive Rights Reader/i) && authstring.match(/^Nancy Ehrenreich/i))
            ||
          (curr.title.match(/^Heterosexism in Contemporary World Religion/i) && authstring.match(/^Marvin Ellison/i))
            ||
          (curr.title.match(/^Religion and the American Radical Tradition/i) && authstring.match(/^Dan McKanan/i))
            ||
          (curr.title.match(/^Disabled Rights/i) && authstring.match(/^Jacqueline Vaughn/i))
            ||
          (curr.title.match(/^Turning Point/i) && authstring.match(/^Fredric Muir/))
            ||
          (curr.title.match(/^Leadership and the New Science/i) && authstring.match(/^Margaret Wheatley/i))
            ||
          (curr.title.match(/^Serving with Grace/i) && authstring.match(/^Erik Wikstrom/i))
            ||
          (curr.title.match(/^The Making of American Liberal Theology/i) && authstring.match(/^Gary Dorrien/i))
            ||
          (curr.title.match(/^The Shared Pulpit/i) && authstring.match(/^Erika Hewitt/i))
            ||
          (authstring.match(/^Edward Searl/i) && curr.title.match(/^(?:In Memoriam|Coming of Age|Readings to Celebrate Marriage|Beyond Absence|Bless this Child)/i))
            ||
          (curr.title.match(/^Preaching Justice/i) && authstring.match(/^Christine Marie Smith/i))
            ||
          (curr.title.match(/^The Pagan Book of Living and Dying/i) && authstring.match(/^Starhawk/i))
            ||
          (curr.title.match(/^Exorcising Preaching/i) && authstring.match(/^Nathan Walker/i))
            ||
          (curr.title.match(/^Generation to Generation/i) && authstring.match(/^Edwin Friedman/i))
            ||
          (curr.title.match(/^Counseling Women/i) && authstring.match(/^Christie Cozad Neuger/i))
            ||
          (curr.title.match(/^Growing Up Absorbed/i) && authstring.match(/^Richard .*\bGilbert$/i))
            ||
          (curr.title.match(/^Fashion Me A People/i) && authstring.match(/^Maria Harris/i))
            ||
          (curr.title.match(/Building the World We Dream About/i) && authstring.match(/^Mark Hicks/i))
            ||
          (curr.title.match(/^Tending the Flame/i) && authstring.match(/^Michelle Richards/i))
            ||
          (curr.title.match(/^Growing Up Generous/i) && authstring.match(/^Eugene Roehlkepartain/i))
            ||
          (curr.title.match(/^A People So Bold/i) && authstring.match(/John Gibb Millspaugh/i))
            ||
          (curr.title.match(/^The Larger Hope/i) && authstring.match(/^Russell .*\bMiller$/i))
            ||
          (curr.title.match(/^A History of God/i) && authstring.match(/^Karen Armstrong/i))
            ||
          (curr.title.match(/^Walking in Others/i) && authstring.match(/^Gretchen Thomas/i))
            ||
          (curr.title.match(/^Against All Odds/i) && authstring.match(/^Brad Christerson/i))
            ||
          (curr.title.match(/^United by Faith/i) && authstring.match(/^Curtiss Paul DeYoung/i))
            ||
          (curr.title.match(/^In This Very Moment/i) && authstring.match(/^James Ishmael Ford/i))
            ||
          (curr.title.match(/^Recovering the Sacred/i) && authstring.match(/^Winona LaDuke/i))
            ||
          (curr.title.match(/^One God/i) && authstring.match(/^Zachary Markwish/i))
            ||
          (curr.title.match(/^The Earth Path/i) && authstring.match(/^Starhawk/i))
            ||
          (curr.title.match(/^Called to Community/i) && authstring.match(/^Dorothy May Emerson/i))
            ||
          (curr.title.match(/^Liberation Theologies in the United States/i) && authstring.match(/Anthony Pinn/i))
        ) {
          curr.description = '(MFC "Familiarity with" Readings) ' + curr.description;
          prev.push(curr);
        }
        
        return prev;
      }, []);

      setAppState({ ...appState, MFC: true, display: display, });
    }
  }
    
  return (
    <>
        <Box
          display="flex"
          alignItems='top'
          justifyContent='center'
          width='100%'
          pl='75'          
          pr='75'
          pt='50'
          mt='0'
          pb='0'
          minH='100'
        >
          <Input variant='outline' placeholder='Search by title or author' name='search' value={appState.search} onChange={runSearch} />            
        </Box>
          
        <Box
          display="flex"
          alignItems='top'
          justifyContent='left'
          width='100%'
          pl='75'          
          pr='75'
          pt='10'
          mt='0'
          pb='50'
          minH='100'
        >
          <Checkbox
              style={{clear: 'both'}}
              isChecked={appState.MFC}
              onChange={toggleMFC}
          > Select MFC Reading List Books </Checkbox>
        </Box>


        <Box
          display="flex"
          alignItems='top'
          justifyContent='center'
          width='100%'
          height='100%'
          pt='50'
          mt='0'
          pl='10'
          pb='50'
          minH='1000'
          bgGradient="linear(to-b, white, #cccccc)"
        >
        
          <VStack spacing={4} align='stretch'>
            <SimpleGrid spacing={4} columns={{base: 1, md: 2, lg: 3, xl: 4}}>
              <Collection />
            </SimpleGrid>
          </VStack>
        </Box>
      </>
  );
}

export default Books;