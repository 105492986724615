import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack, CircularProgress,
  Code,
  Grid,
  theme,
  Button,
  Wrap, Center,
  Card, CardHeader, CardBody, Heading, Stack, StackDivider
} from '@chakra-ui/react';
import { useAppStateStore } from "./AppStateProvider";

function Response() {
  const { appState, setAppState } = useAppStateStore();
  
  if (appState.payment) {
    if ("paid" === appState.payment.payment_status) {
      return (
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Thanks!</Heading>
          </CardHeader>
        
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Text pt='2' fontSize='md'>Your donation of ${parseFloat(appState.payment.amount_total / 100).toFixed(2)} was successful.</Text>              
            </Stack>
          </CardBody>
        </Card>
      );    
    }
    else {
      return (
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Thanks!</Heading>
          </CardHeader>
        
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Text pt='2' fontSize='md'>
              There appears to have been a problem processing your payment. Please try 
              again or contact us. 
              </Text>              
            </Stack>
          </CardBody>
        </Card>
      );        
    }
  }
  else {
    return (
      <Card  width={{base: '90%', lg: "50%"}}>
        <CardHeader>
          <Heading size='md'>Fetching information&hellip;</Heading>
        </CardHeader>
      
        <CardBody>
          <Center><CircularProgress value={33} size='120px' /></Center>
        </CardBody>
      </Card>
    );
  }
}


function Donation() {
  const { appState, setAppState } = useAppStateStore();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const session_id = searchParams.get('session_id');
  
  useEffect(() => {
    
    fetch(appState.endpoint + 'session?id=' + session_id, {
      method : "GET"
    })
    .then((res) => {
      res.json().then((data) => {
        setAppState({ ...appState, payment : data.payment, });
      });
    })
    .catch((err) => {
      console.error(err);
      alert('error');
    });        
  }, []);

  return (
        <Box
          display="flex"
          alignItems='top'
          justifyContent='center'
          width='100%'
          height='100%'
          pT='50'
          mT='0'
          minH='1000'
          bgGradient="linear(to-b, white, #cccccc)"
        >
          <Response />        
        </Box>
  );
}

export default Donation;