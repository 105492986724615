import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Text,
  CircularProgress,
  Center,
  Card, CardHeader, CardBody, Heading, Stack, StackDivider
} from '@chakra-ui/react';
import { useAppStateStore } from "./AppStateProvider";

function Response() {
  const { appState } = useAppStateStore();
  
  if (appState.payment) {
    if ("paid" === appState.payment.payment_status) {

      fetch(appState.endpoint + 'orders', {
        method : 'PUT',
        body   : JSON.stringify({
          'token'   : appState.token,
          'pay_id'  : appState.payment.payment_intent.id,
          'ship_id' : appState.ship_id,
        })
      })    
    
      return (
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Thanks!</Heading>
          </CardHeader>
        
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Text pt='2' fontSize='md'>Your payment of 
              ${parseFloat(appState.payment.amount_total / 100).toFixed(2)} 
              &nbsp; was successful. Your order will be on the way soon!</Text>              
            </Stack>
          </CardBody>
        </Card>
      );    
    }
    else {
      return (
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Thanks!</Heading>
          </CardHeader>
        
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Text pt='2' fontSize='md'>
              There appears to have been a problem processing your payment. Please try 
              again or contact us. 
              </Text>              
            </Stack>
          </CardBody>
        </Card>
      );        
    }
  }
  else {
    return (
      <Card  width={{base: '90%', lg: "50%"}}>
        <CardHeader>
          <Heading size='md'>Fetching information&hellip;</Heading>
        </CardHeader>
      
        <CardBody>
          <Center><CircularProgress value={33} size='120px' /></Center>
        </CardBody>
      </Card>
    );
  }
}


function Payment() {
  const { appState, setAppState } = useAppStateStore();
  
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get('session_id');
  const token      = searchParams.get('token');
  const ship_id    = searchParams.get('ship_id');
  
  useEffect(() => {    
/* 
    fetch(appState.endpoint + 'session?test=1&id=' + session_id, {
      method : "GET"
    })
 */
    fetch(appState.endpoint + 'session?id=' + session_id, {
      method : "GET"
    })
    .then((res) => {
      res.json().then((data) => {
        setAppState({ ...appState, payment : data.payment, token : token, ship_id : ship_id, });
      });
    })
    .catch((err) => {
      console.error(err);
      alert('error');
    });        
  }, []);

  return (
        <Box
          display="flex"
          alignItems='top'
          justifyContent='center'
          width='100%'
          height='100%'
          pT='50'
          mT='0'
          minH='1000'
          bgGradient="linear(to-b, white, #cccccc)"
        >
          <Response />        
        </Box>
  );
}

export default Payment;