import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Code,
  Grid,
  theme,
  Button, 
  Wrap, Center,
  Card, CardHeader, CardBody, Heading, Stack, StackDivider
} from '@chakra-ui/react';

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import AppStateProvider, { useAppStateStore } from "./AppStateProvider";
import Home from "./Home";
import Donate from "./Donate";
import Books from "./Books";
import Cart from "./Cart";
import Donation from "./Donation";
import Payment from "./Payment";

function Main() {
  const navigate = useNavigate();

  const email = () => {
    window.location.href = 'mailto:revnato@sacredfireuu.org';
    return true;
  };

  return (
    <Routes>
      <Route path='/' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/dawn.jpg')"
          bgPosition='fit'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='red' size='lg'>
              Home
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='orange' size='lg' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
      
        <Home />
        </>
      } />
  
      <Route path='/books' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/dawn.jpg')"
          bgPosition='fit'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='red' size='lg'>
              Shop for Books
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='orange' size='lg' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Books />
        </>
      } />
  
      <Route path='/donate' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/dawn.jpg')"
          bgPosition='fit'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='red' size='lg'>
              How to Donate
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='orange' size='lg'>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Donate />
        </>
      } /> 

      <Route path='/cart' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/dawn.jpg')"
          bgPosition='fit'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='red' size='lg'>
              Cart
            </Button>
            <Button colorScheme='orange' size='lg'>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Cart />
        </>
      } /> 

      <Route path='/donation' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/dawn.jpg')"
          bgPosition='fit'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='orange' size='lg' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Donation />
        </>
      } /> 

      <Route path='/payment' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/dawn.jpg')"
          bgPosition='fit'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='orange' size='lg' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='orange' size='lg' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Payment />
        </>
      } /> 
    </Routes>
  );
}


function App() {
  return (
    <ChakraProvider>
      <AppStateProvider>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </AppStateProvider>
    </ChakraProvider>
  );
}

export default App;
