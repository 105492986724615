import React, { useEffect } from 'react';
import {
  Box,
  Text,
  Input,
  Button,
  VStack,
  Badge,
  Card, CardHeader, CardBody, Heading, Stack, StackDivider
} from '@chakra-ui/react';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useAppStateStore } from "./AppStateProvider";

const stripePromise = loadStripe('pk_live_zLpvgCMFCrwHNWf2gOxn4VkO');

function DonateCash() {
  const { appState, setAppState } = useAppStateStore();

  const updateField = (evt) => {
    const errors = { amtInvalid : false, };
    setAppState({ ...appState, ...errors, [evt.target.name]: evt.target.value, });
  };

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });  
    
  const goPayment = () => {
    const amount = parseFloat(appState.donationAmt.replace(/[^.\d]/g, ''));
    if (amount > 0) {
      setAppState({ ...appState, loading: true, });
      fetch(appState.endpoint + 'session', {
        method : 'POST',
        body   : JSON.stringify({amount : parseInt(appState.donationAmt * 100)})
      })
      .then((res) => {
        res.json().then((data) => {
          if ('OK' === data.status) {
            setAppState({ 
              ...appState, 
              loading : false, 
              session : data['session'],
              donationAmt : amount,
              amtInvalid  : false, 
            });
          }
          else {
            setAppState({ ...appState, loading: false, });
            alert('Error saving data. Please try again.');
          }
        })
      })
      .catch((err) => {
        console.error(err);
        setAppState({ ...appState, loading: false, });
        alert('error');
      });    
    }
    else {
      setAppState({ ...appState, amtInvalid: true, });
    }
  };
    
  if (appState.session) {
    const fetchClientSecret = () => {
      return appState.session.client_secret;
    };

    const options = {fetchClientSecret};
    
    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pT='50'
        mT='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Donation Processing Details</Heading>
          </CardHeader>
        
          <CardBody>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={options}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>          
          </CardBody>
        </Card>
      </Box>
    )
  }
  else {
    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pT='50'
        mT='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
      
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Tax Deductable Monetary Donation</Heading>
          </CardHeader>
        
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Text pt='2' fontSize='md'>Amount of Donation:</Text>
              
              <Input variant='outline' placeholder='$ ' name='donationAmt' value={(appState.donationAmt && !isNaN(appState.donationAmt)) ? appState.donationAmt : ''} onChange={updateField} isInvalid={appState.amtInvalid} />            
              
              <Button colorScheme='blue' size='lg' onClick={goPayment} isLoading={appState.loading}>Continue</Button>
            </Stack>
          </CardBody>
        </Card>
  
      </Box>  
    );  
  }

}

function DonateBooks() {
  const { appState, setAppState } = useAppStateStore();

  const updateField = (evt) => {
    const errors = { nameInvalid : false, emailInvalid : false, booksInvalid : false, };
    const donor = { ...appState.donor, ...errors, [evt.target.name]: evt.target.value, };
    setAppState({ ...appState, donor: donor });
  };
  
  const submit = () => {
    const errors = { nameInvalid : false, emailInvalid : false, booksInvalid : false, };
    if (!appState.donor.name) {
      errors.nameInvalid = true;
    }
    if (!appState.donor.email.match(/\w@\w/)) {
      errors.emailInvalid = true;
    }
    if (!appState.donor.books) {
      errors.booksInvalid = true;
    }
    if (errors.nameInvalid || errors.emailInvalid || errors.booksInvalid) {
      const donor = { ...appState.donor, ...errors, };
      setAppState({ ...appState, donor : donor, });
    }
    else {
      const donor = { ...appState.donor, loading: true, message: '', };
      setAppState({ ...appState, donor : donor, });
    
      fetch(appState.endpoint + 'address', {
        method : "POST",
        body   : JSON.stringify({
          name    : appState.donor.name,
          street1 : appState.donor.address,
          street2 : appState.donor.apartment,
          city    : appState.donor.town,
          state   : appState.donor.state,
          zip     : appState.donor.zip         
        })
      })
      .then((res) => {
        res.json().then((data) => {
          if (data.is_valid) {
            // save info to database. Be sure message is clear.
            fetch(appState.endpoint + 'donor', {
              method : "POST",
              body   : JSON.stringify(appState.donor)
            })
            .then((res) => {
              res.json().then((data) => {
                if ('OK' === data.status) {
                  const donor = { ...appState.donor, id : data.id, };
                  setAppState({
                    ...appState,
                    donor   : donor,
                    loading : false,
                    message : '',
                  });
                }
                else {
                  const donor = { ...appState.donor, loading: false, message : 'Error saving data. Please try again.'};
                  setAppState({ ...appState, donor : donor, });
                }
              })        
            })
            .catch((err) => {
              console.error(err);
              const donor = { ...appState.donor, loading: false, message : 'Software/server error.'};
              setAppState({ ...appState, donor: donor, });
            });    
          }
          else {
            const donor = { ...appState.donor, loading: false, message: data.message, };
            setAppState({
              ...appState, 
              donor   : donor,
              loading : false,
            })
          }
        });
      })
    
    
    
      
    }
  };
    
  return (
        <Box
          display="flex"
          alignItems='top'
          justifyContent='center'
          width='100%'
          height='100%'
          pT='50'
          mT='0'
          minH='1000'
          bgGradient="linear(to-b, white, #cccccc)"
        >
        
        {
          appState.donor.id ? 
             <Card  width={{base: '90%', lg: "50%"}}>
              <CardHeader>
                <Heading size='md'>Thanks for your Book Donation!</Heading>
              </CardHeader>
            
              <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Mailing your Donated Books:
                    </Heading>   
                    <Text pt='2' fontSize='md'>
                      Please include a note marking your shippment with ID "{appState.donor.id}" 
                      and send it to:
                    </Text>  
                    <Text pt='2' fontSize='md'>
                      Minister Book Exchange c/o Sacred Fire UU<br />
                      301 Davie Rd, Carrboro NC 27510-1607
                    </Text>
                    <Text pt='2' fontSize='md'>
                      To save on shipping costs, you can send books 
                      using the "Media Mail" rate through the US Postal Service.
                    </Text>
                    <Text pt='2' fontSize='md'>
                      If you'd like us to include the shipping costs for your books, in
                      addition to the value of the books you are sending, in the letter we
                      send you for use in documenting your tax-deducatable donation,
                      feel free to mail a copy of your receipt to the address above. Be sure
                      to include the generated ID for your donation: "{appState.donor.id}".
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
         
          : 
            <Card  width={{base: '90%', lg: "50%"}}>
              <CardHeader>
                <Heading size='md'>How to Donate</Heading>
              </CardHeader>
            
              <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Donating Books to the Minister Book Exchange
                    </Heading>   
                    <Text pt='2' fontSize='md'>
                      <ol>
                        <li>Fill out the following form</li>
                        <li>Mail books to the address indicated on the next page, which will also be sent to you by email</li>
                      </ol>
                    </Text>  
                    <br />
                    <VStack spacing={4} align='stretch'>
                      <Input variant='outline' placeholder='Your Name' name='name' value={appState.donor.name} onChange={updateField} isInvalid={appState.donor.nameInvalid} />
                      <Input variant='outline' placeholder='In Memory of (optional)' name='memory' value={appState.donor.memory} onChange={updateField} />           
                      <Input variant='outline' placeholder='Mailing Address' name='address' value={appState.donor.address} onChange={updateField} />
                      <Input variant='outline' placeholder='Address Line 2 (optional)' name='apartment' value={appState.donor.apartment} onChange={updateField} />
                      <Input variant='outline' placeholder='Town' name='town' value={appState.donor.town} onChange={updateField} />
                      <Input variant='outline' placeholder='State' name='state' value={appState.donor.state} onChange={updateField} />
                      <Input variant='outline' placeholder='Zip Code' name='zip' value={appState.donor.zip} onChange={updateField} />
                      <Input variant='outline' placeholder='Email' name='email' value={appState.donor.email} onChange={updateField} isInvalid={appState.donor.emailInvalid} />
                      <Input variant='outline' placeholder='Phone' name='phone' value={appState.donor.phone} onChange={updateField} />                  
                      <Input variant='outline' placeholder='How Many Books?' name='books' value={appState.donor.books} onChange={updateField} isInvalid={appState.donor.booksInvalid} />
                      {
                        appState.donor.message && 
                        <Badge colorScheme='red'>{appState.donor.message}</Badge>
                      }
                      <Button colorScheme='blue' size='lg' onClick={submit} isLoading={appState.donor.loading} loadingText='Submitting'>Continue</Button>
                    </VStack>
                  
                  </Box>
                </Stack>
              </CardBody>
            </Card>
        
        }
        
        
        </Box>
  );
}

function Donate() {
  const { appState, setAppState } = useAppStateStore();
  
  const goBooks = () => {
    setAppState({ ...appState, donationType : 'books', });
  };
  
  const goCash = () => {
    window.location.href = 'https://www.sacredfireuu.org/#donate';
    return null;
  };
  
  useEffect(() => {
    setAppState({ 
      ...appState, 
      donationType : '', 
      amtInvalid   : false, 
      loading      : false, 
      session      : '',
    });
  }, []);
  
  if (appState.donationType) {
    if ('books' === appState.donationType) {
      return <DonateBooks />
    }
  }
  else {
    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pT='50'
        mT='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
       <Card  width={{base: '90%', lg: "50%"}}>
        <CardHeader>
          <Heading size='md'>Donation Options</Heading>
        </CardHeader>
        
        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Button colorScheme='blue' size='lg' onClick={goBooks}>Donate Books</Button>
            
            <Button colorScheme='blue' size='lg' onClick={goCash}>Make a Tax Deductable Monetary Donation</Button>
          </Stack>
        </CardBody>
        
        </Card>
      </Box>
    );
  }
}
    


export default Donate;