import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Wrap, Center,
  Card, CardHeader, CardBody, Heading, Stack, StackDivider
} from '@chakra-ui/react';

function Home() {

  return (
        <Box
          display="flex"
          alignItems='top'
          justifyContent='center'
          width='100%'
          height='100%'
          pT='50'
          mT='0'
          minH='1000'
          bgGradient="linear(to-b, white, #cccccc)"
        >
        
          <Card  width={{base: '90%', lg: "50%"}}>
            <CardHeader>
              <Heading size='md'>The Minister Book Exchange</Heading>
            </CardHeader>
          
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Text pt='2' fontSize='md'>
Our ministry of Sacred Fire Unitarian Universalist has as its charge to build learning communities, as well as plant covenanted communities and increase the impact of our congregantions’ social- and racial-justice work. In this endeavor, we are launching the Minister Book Exchange.
                  </Text>
                  <Text pt='2' fontSize='md'>
When our ministers retire or pass on, we have—let’s be honest—many, many books. It is a time-honored tradition to pass these books on to current divinity school students. Sacred Fire UU has set up a process through which those books can be donated to the Minister Book Exchange. We have built database software that allows us to scan the books and assess their value. This value, including the shipping costs, are a charitable contribution, with the attendant tax write-offs.
                  </Text>
                  <Text pt='2' fontSize='md'>
When books are donated, we scan them in and issue ministers a letter of charitable contribution for their value. We then put the books on our online bookstore at below-market cost, and are connected with our divinity schools to put them in the hands of our ministers-to-be. The donated books will be adorned with a sticker on the inside cover saying, “This book was lovingly donated by Rev. ___,” to build our collegial connections.
                  </Text>
                  <Text pt='2' fontSize='md'>
All proceeds from the book sales will go directly to our ongoing social- and racial-justice work. We are engaged in solidarity with refugee’d and immigrant communities, the movement for Black lives, food security, and justice in housing and jobs. Additionally, we consult with congregations nationwide through our social- and racial-justice workshop series.
                  </Text>
                  <Text pt='2' fontSize='md'>
So pack up your books and ship them at “book rate” to our address below. We will get them to those who need them, honor the gifts of our ministers, and bolster our continuing work for our faith of Unitarian Universalism and the wider world.
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        
        </Box>
  );
}

export default Home;